import { LatLng, LatLngBounds, LatLngBoundsExpression } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { ImageOverlay, Pane, Polygon } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import GeoUtil from '../../../lib/geo-util';
import SatelliteUtil from '../../../lib/satellite-util';
import {
    actionSentinelFeatureLoading,
    actionSentinelFetchFeaturesError,
    actionSentinelSelectFeature,
} from '../../../store/Map/Sentinel/actions';
import {
    selectSentinelError,
    selectSentinelIsBoxSelectActive,
    selectSentinelSelectedFeatureOpacity,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
    selectSentinelFeatureLoading,
} from '../../../store/Map/Sentinel/selectors';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import ImageOverlayEvented from '../image-overload-evented';
import { isMobileVersion } from '../../../lib/soar-helper';
import ApiCfSentinel from '../../../api/api-cf-sentinel';
import { updateShortUrl } from '../../Drawer/Satellites/Sentinel/Shared/use-short-url';

interface SentinelFeaturesProps {
    isViewStoryMap?: boolean;
}

const SENTINEL_PANE = 'sentinelPane';

const SentinelFeatures = ({ isViewStoryMap }: SentinelFeaturesProps) => {
    const sentinelSelectedAOI = useSelector(selectSentinelSelectedAOI);
    const sentinelSelectedFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelOverlayOpacity = useSelector(selectSentinelSelectedFeatureOpacity);
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const isBoxSelectActive = useSelector(selectSentinelIsBoxSelectActive);
    const sentinelError = useSelector(selectSentinelError);
    const isSentinelFeatureLoading = useSelector(selectSentinelFeatureLoading);

    //const [isLoadingSentinelImage, setIsLoadingSentinelImage] = useState(false);
    const [apiImageKey, setApiImageKey] = useState('');
    const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);

    const dispatch = useDispatch();

    const squarePolygonForBounds = (bounds: LatLngBounds): LatLngBounds => {
        const ns = bounds.getNorth() - bounds.getSouth();
        const ew = bounds.getEast() - bounds.getWest();

        if (Math.abs(ns) > Math.abs(ew)) {
            const north = bounds.getSouth() + ns / 2 + ns / 4;
            const south = bounds.getSouth() + ns / 2 - ns / 4;
            const east = bounds.getEast();
            const west = bounds.getWest();
            return new LatLngBounds(new LatLng(south, west), new LatLng(north, east));
        } else {
            const north = bounds.getNorth();
            const south = bounds.getSouth();
            const east = bounds.getWest() + ew / 2 + ew / 4;
            const west = bounds.getWest() + ew / 2 - ew / 4;
            return new LatLngBounds(new LatLng(south, west), new LatLng(north, east));
        }
    };

    const handleOverlayErrorMessage = (errMessage: string, err: string) => {
        if (errMessage && sentinelSelectedFeature && !isSentinelFeatureLoading) {
            dispatch(actionSentinelFetchFeaturesError(errMessage));
            SatelliteUtil.sendSentinelSlackMessage(
                `ERROR : ${sideDrawerMode} - FAILED TO LOAD IMAGE`,
                `TYPE: ${err}`,
                `BBOX: ${sentinelSelectedFeature.bbox}, EVAL: ${sentinelSelectedFeature?.evalScript?.name}`
            );
        }
    };

    useEffect(() => {
        // Reset when sentinel feature changes
        actionSentinelFeatureLoading(false);
        setPreviewUrl(undefined);
    }, [sentinelSelectedFeature]);

    useEffect(() => {
        if (!sentinelSelectedFeature) return;

        const fetchSentinelImage = async () => {
            // Fetch preview
            if (!sentinelSelectedFeature?.previewUrl?.length && !isSentinelFeatureLoading && !previewUrl) {
                actionSentinelFeatureLoading(true);

                const preview = await ApiCfSentinel.updateSentinelPreviewImage(
                    sentinelSelectedFeature,
                    sentinelSelectedFeature?.evalScript
                );
                if (preview.error) {
                    actionSentinelFeatureLoading(false);
                    dispatch(actionSentinelFeatureLoading(false));
                    return;
                }

                if (preview.imageUrl !== previewUrl) {
                    const updatedFeature = { ...sentinelSelectedFeature, previewUrl: preview.imageUrl };
                    dispatch(actionSentinelSelectFeature(updatedFeature));
                    setPreviewUrl(preview.imageUrl);
                }
            }

            // fetch high-res image
            if (!sentinelSelectedFeature?.highResolutionPreviewUrl) {
                actionSentinelFeatureLoading(true);

                // Update the short when we know we can fetch a high res image/otherwise wait but will take longer to update
                updateShortUrl(sentinelSelectedFeature);

                try {
                    const selectedQuality = SatelliteUtil.getSelectedQuality(
                        sentinelSelectedFeature,
                        sentinelSelectedFeature.resolution
                    );

                    const { imageUrl, error } = await ApiCfSentinel.updateSentinelImage(
                        sentinelSelectedFeature,
                        sentinelSelectedFeature.evalScript,
                        selectedQuality?.widthPixels ?? 0,
                        selectedQuality?.heightPixels ?? 0,
                        undefined,
                        undefined,
                        'image/png',
                        undefined,
                        (key) => setApiImageKey(key)
                    );

                    if (error) {
                        actionSentinelFeatureLoading(false);
                        dispatch(actionSentinelFeatureLoading(false));
                        return;
                    }

                    if (imageUrl && imageUrl !== sentinelSelectedFeature?.highResolutionPreviewUrl) {
                        const updatedFeature = { ...sentinelSelectedFeature, highResolutionPreviewUrl: imageUrl };
                        dispatch(actionSentinelSelectFeature(updatedFeature));
                    }
                } catch (error) {
                    console.error('Error fetching high-res image:', error);
                } finally {
                    dispatch(actionSentinelFeatureLoading(false));
                    setPreviewUrl(undefined);
                }
            }
        };

        fetchSentinelImage();

        // Cleanup function to cancel any ongoing request if the selected feature changes
        return () => {
            if (apiImageKey) {
                ApiCfSentinel.cancelToken(apiImageKey);
                setApiImageKey('');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sentinelSelectedFeature,
        sentinelSelectedFeature?.previewUrl,
        sentinelSelectedFeature?.highResolutionPreviewUrl,
        sentinelSelectedFeature?.resolution,
    ]);

    return (
        <Pane name={SENTINEL_PANE}>
            {(isSentinelFeatureLoading && sentinelSelectedAOI) ||
            (sentinelSelectedAOI &&
                !sentinelSelectedFeature &&
                !isBoxSelectActive &&
                !isMobileVersion &&
                !isViewStoryMap) ? (
                <Polygon
                    positions={GeoUtil.polygonForBounds(sentinelSelectedAOI)}
                    fillColor="transparent"
                    interactive={false}
                    weight={1}
                />
            ) : null}
            {sentinelSelectedAOI && sentinelSelectedFeature ? (
                <React.Fragment>
                    {/* Loading */}
                    {isSentinelFeatureLoading && (
                        <ImageOverlay
                            url="/assets/loading.gif"
                            zIndex={20}
                            interactive={false}
                            bounds={
                                GeoUtil.polygonForBounds(
                                    squarePolygonForBounds(sentinelSelectedAOI)
                                ) as LatLngBoundsExpression
                            }
                        />
                    )}

                    {/* Preview */}
                    {sentinelSelectedFeature?.previewUrl && isSentinelFeatureLoading && !sentinelError && (
                        <ImageOverlay
                            url={sentinelSelectedFeature?.previewUrl}
                            zIndex={1}
                            interactive={false}
                            bounds={sentinelSelectedFeature.bbox as LatLngBoundsExpression}
                            opacity={sentinelOverlayOpacity ?? 1.0}
                        />
                    )}

                    {/* HighRes Loaded */}
                    {!isSentinelFeatureLoading && sentinelSelectedFeature?.highResolutionPreviewUrl ? (
                        <ImageOverlayEvented
                            onStart={() => dispatch(actionSentinelFeatureLoading(true))}
                            onLoad={() => {
                                dispatch(actionSentinelFeatureLoading(false));
                            }}
                            onError={(err: Error) => {
                                dispatch(actionSentinelFeatureLoading(false));
                                handleOverlayErrorMessage(
                                    "Sorry, this service is temporarily unavailable. We're working to get it up and running as soon as possible. Please check in again later.",
                                    err.message
                                );
                            }}
                            url={sentinelSelectedFeature?.highResolutionPreviewUrl}
                            zIndex={2}
                            bounds={sentinelSelectedFeature.bbox as LatLngBoundsExpression}
                            opacity={sentinelOverlayOpacity ?? 1.0}
                            pane={SENTINEL_PANE}
                        />
                    ) : null}
                </React.Fragment>
            ) : null}
        </Pane>
    );
};

export default SentinelFeatures;
