import { LatLngBounds } from 'leaflet';
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { trueColor } from '../components/Drawer/Satellites/Sentinel/sentinel-default-evalscripts';
import Api from '../api/api';
import ApiCfSentinel from '../api/api-cf-sentinel';
import { ListingDTO } from '../api/model';
import { handleDeprecatedSentinelStringUrl } from '../components/Drawer/Satellites/Sentinel/sentinel-deprecated-request-util';
import {
    actionSentinelFetchFeatures,
    actionSentinelSelectAOI,
    actionSentinelSelectFeature,
} from '../store/Map/Sentinel/actions';
import { DeprecatedSentinelRequestParams, SentinelFeature } from '../store/Map/Sentinel/model';
import store from '../store/store';
import GeoUtil from './geo-util';
import ListingHelper from './listing-helper';
import SatelliteUtil from './satellite-util';
import UriHelper from './uri-helper';
import moment from 'moment';
import SentinelUtil from './sentinel-util';

const BASE_URL = `https://${Api.getSubdomain()}`;
export default class ShareService {
    public static async sentinelFeatureFromUri(uri: string) {
        const urlParams = ShareService.parseQueryString(uri) as DeprecatedSentinelRequestParams;
        if (!urlParams?.shareId && !urlParams?.service) {
            return;
        }

        const sharedSentinelFeature = urlParams.service
            ? await handleDeprecatedSentinelStringUrl(urlParams)
            : await handleNewShortUrl(urlParams.shareId);

        if (!sharedSentinelFeature) return;

        await ShareService.updateAndDispatchSentinelFeature(sharedSentinelFeature);
        return sharedSentinelFeature;
    }

    private static async updateAndDispatchSentinelFeature(satelliteFeature: SentinelFeature) {
        const evalScript = satelliteFeature.evalScript ?? trueColor;
        const dimensions = SatelliteUtil.sentinelQualityEvaluation(satelliteFeature.bbox).filter(
            (dimension) => dimension.isAvailable
        );

        const startDate = moment(satelliteFeature.date).startOf('month').toDate(); // start of the month
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dispatch = store.dispatch as Dispatch<any>;

        dispatch(actionSentinelSelectAOI(satelliteFeature.bbox));

        dispatch(
            actionSentinelFetchFeatures(
                satelliteFeature.bbox,
                startDate,
                moment(startDate).add(1, 'month').toDate(),
                [satelliteFeature.collection],
                50,
                evalScript,
                satelliteFeature.resolution ?? dimensions[0].resolution
            )
        );

        dispatch(actionSentinelSelectFeature(satelliteFeature));
    }

    public static addParamsToUrl = (url, key) => {
        const param = UriHelper.tryGetParam(key);
        return param ? url.searchParams.set(key, param) : '';
    };

    public static shareMapUrl(mapId: number | string): string {
        const url = new URL(`${BASE_URL}/maps/${mapId}`);
        ShareService.addParamsToUrl(url, 'pos');
        ShareService.addParamsToUrl(url, 'basemap');
        return url.toString();
    }

    public static shareMobileAppUrl(listing: ListingDTO): string {
        const url = new URL(ShareService.shareMapUrl(listing.id));
        url.searchParams.set('mobileShareLink', 'true');
        url.searchParams.set('previewUrl', ListingHelper.getPreviewUrlForListing(listing.id, 'small'));
        url.searchParams.set('title', ListingHelper.getTitleFromListing(listing));
        return url.toString();
    }

    public static parseQueryString(query: string) {
        query = query.split('?')[1];
        if (!query) {
            return {};
        }
        const vars = query.split('&');
        const queryString = {};
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            const key = decodeURIComponent(pair[0]);
            const value = decodeURIComponent(pair[1]);
            // If first entry with this name
            if (typeof queryString[key] === 'undefined') {
                queryString[key] = decodeURIComponent(value);
                // If second entry with this name
            } else if (typeof queryString[key] === 'string') {
                const arr = [queryString[key], decodeURIComponent(value)];
                queryString[key] = arr;
                // If third or later entry with this name
            } else {
                queryString[key].push(decodeURIComponent(value));
            }
        }
        return queryString;
    }
}

async function handleNewShortUrl(shareId: string): Promise<SentinelFeature | null> {
    if (!shareId) return null;
    const response = await ApiCfSentinel.getSentinelShortUrl(shareId);
    if (!response) {
        toast.error('Invalid or expired share ID.');
        return null;
    }

    const { id, bbox, date, evalscript, satellite, imageUrl } = response;

    let parsedBbox = new LatLngBounds(
        GeoUtil.EPSG3857ToLatLng(bbox[0], bbox[1]),
        GeoUtil.EPSG3857ToLatLng(bbox[2], bbox[3])
    );

    if (Math.abs(parsedBbox.getSouthWest().lat) < 0.01 && Math.abs(parsedBbox.getSouthWest().lng) < 0.01) {
        parsedBbox = new LatLngBounds([bbox[1], bbox[0]], [bbox[3], bbox[2]]);
    }

    const evalScript = evalscript ?? trueColor;

    return {
        id: id,
        bbox: parsedBbox,
        date: date,
        resolution: SentinelUtil.pixelResolutionForBounds(parsedBbox),
        cloudCover: 0,
        satellite: evalScript.sentinelSatelliteType,
        constellation: evalScript.sentinelSatelliteType,
        previewUrl: imageUrl ?? '',
        highResolutionPreviewUrl: undefined,
        collection: satellite,
        evalScript: evalScript,
    };
}
